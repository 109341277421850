import * as ReactUltimatePagination from "react-ultimate-pagination";
import PaginationItem from "./pagination-item/PaginationItem";
import styles from "./Pagination.module.scss";

const Page = (props: ReactUltimatePagination.PaginationComponentProps) => {
  return (
    <PaginationItem
      isActive={props.isActive}
      onClick={props.onClick}
      text={props.value.toString()}
    />
  );
};

const Ellipsis = (props: ReactUltimatePagination.PaginationComponentProps) => {
  return <PaginationItem onClick={props.onClick} text="..." />;
};

const FirstPageLink = (
  props: ReactUltimatePagination.PaginationComponentProps
) => {
  return <PaginationItem onClick={props.onClick} icon="DoubleChevronLeft" />;
};

const PreviousPageLink = (
  props: ReactUltimatePagination.PaginationComponentProps
) => {
  return <PaginationItem onClick={props.onClick} icon="ChevronLeft" />;
};

const NextPageLink = (
  props: ReactUltimatePagination.PaginationComponentProps
) => {
  return <PaginationItem onClick={props.onClick} icon="ChevronRight" />;
};

const LastPageLink = (
  props: ReactUltimatePagination.PaginationComponentProps
) => {
  return <PaginationItem onClick={props.onClick} icon="DoubleChevronRight" />;
};

const Wrapper = (props: any) => {
  return <div className={styles.pagination}>{props.children}</div>;
};

var itemTypeToComponent = {
  PAGE: Page,
  ELLIPSIS: Ellipsis,
  FIRST_PAGE_LINK: FirstPageLink,
  PREVIOUS_PAGE_LINK: PreviousPageLink,
  NEXT_PAGE_LINK: NextPageLink,
  LAST_PAGE_LINK: LastPageLink,
};

var Pagination = ReactUltimatePagination.createUltimatePagination({
  itemTypeToComponent: itemTypeToComponent,
  WrapperComponent: Wrapper,
});

export default Pagination;
