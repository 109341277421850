import {
  PrimaryButton,
  DetailsList,
  Label,
  Stack,
  StackItem,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
  SearchBox,
  IconButton,
  Icon,
  mergeStyles,
  Pivot,
  PivotItem,
  StickyPositionType,
  Sticky,
  MessageBar,
  MessageBarType,
  Modal,
  mergeStyleSets,
  TooltipHost,
  ITooltipHostStyles,
  IDropdownOption,
} from "@fluentui/react";

import React, { useEffect, useMemo, useState } from "react";
import styles from "./home.module.scss";
import stylesNewProject from "../newProject/newProject.module.scss";
import { useNavigate } from "react-router-dom";
import { IProject } from "../../../common/models/IProject";
import ProjectService from "../../../common/api-services/ProjectServices";
import Pagination from "../../common/pagination/Pagination";
import { useBoolean, useId } from "@fluentui/react-hooks";
import CommonService from "../../../common/api-services/CommonService";
import { ICompany } from "../../../common/models/ICompany";
import { ICommon } from "../../../common/models/ICommon";
import { IAzureUserInformation } from "../../../common/models/IUserDetails";
import { IReportDocument } from "../../../common/models/IReportDocument";

const Home: React.FC = () => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [currentUser, setCurrentUser] = useState<IAzureUserInformation>();
  const [projectsFiltred, setProjectsFiltred] = useState<IProject[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [statusFilter, setStatusFilter] = useState<string>("");
  const [projectIdToDelete, setProjectIdToDelete] = useState<string>("");
  const [projectToArchive, setProjectToArchive] = useState<IProject>();
  const [projectToReactivate, setProjectToReactive] = useState<IProject>();
  const [modalText, setModalText] = useState<string>("");
  const [deleteModalAction, isDeleteModalAction] = useState<boolean>(true);
  const service = useMemo(() => new ProjectService(), []);
  const serviceCommon = useMemo(() => new CommonService(), []);
  const [companies, setCompanies] = useState<ICommon[]>([]);
  const [message, setMessage] = useState<string>("");
  const [messageBarType, setMessageBarType] = useState<MessageBarType>();
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [searchValue, setSearchValue] = useState("");
  const calloutProps = { gapSpace: 0 };
  const tooltipArchiveId = useId("tooltipArchiveProject");
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block" },
  };
  const iconClass = mergeStyles({
    fontSize: 16,
    height: 24,
    width: 24,
    color: "green",
  });
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/project`;
    navigate(path);
  };
  const _onFormatDate = (date: string): string => {
    const myDate = new Date(date);

    return (
      ("0" + myDate.getDate()).slice(-2) +
      "." +
      ("0" + (myDate.getMonth() + 1)).slice(-2) +
      "." +
      myDate.getFullYear()
    );
  };
  const columns: IColumn[] = [
    {
      key: "column1",
      name: "Created",
      fieldName: "createdDate",
      minWidth: 50,
      maxWidth: 60,
      onRender: (item: any) => {
        let date = item.createdDate;

        return _onFormatDate(item.createdDate);
      },
    },
    {
      key: "column10",
      name: "Status",
      fieldName: "status",
      minWidth: 50,
      maxWidth: 80,
      onRender: (item: any) => {
        return (
          <Stack horizontalAlign="start" style={{ minWidth: 100 }}>
            {item.status == "Archived" && (
              <Label disabled className={styles.Archived}>
                {item.status}
              </Label>
            )}
            {item.status == "Active" && (
              <Label disabled className={styles.Active}>
                {item.status}
              </Label>
            )}
            {item.status == "Draft" && (
              <Label disabled className={styles.Draft}>
                {item.status}
              </Label>
            )}
          </Stack>
        );
      },
    },
    {
      key: "column2",
      name: "Project",
      fieldName: "name",
      minWidth: 80,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      data: "string",
    },
    {
      key: "column3",
      name: "Category",
      fieldName: "category",
      minWidth: 60,
      maxWidth: 60,
      isRowHeader: true,
      data: "string",
      isPadded: false,
    },
    {
      key: "column4",
      name: "RB company",
      fieldName: "company",
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,

      data: "string",
      isPadded: false,
    },
    {
      key: "column5",
      name: "Client",
      fieldName: "client",
      minWidth: 80,
      maxWidth: 150,
      isRowHeader: true,
      data: "string",
      isPadded: false,
    },
    {
      key: "column6",
      name: "Target",
      fieldName: "target",
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      isPadded: false,
    },
    {
      key: "column7",
      name: "Acceptance and download status",
      fieldName: "statistics",
      minWidth: 100,
      maxWidth: 250,
      isRowHeader: true,
      data: "string",
      isPadded: false,
      onRender: (item: any) => {
        let acceptedItem = item.recipients
          ? item.recipients.filter((e: any) => {
              return e.termsConditionAccepted || e.AcceptanceDate != null;
            }).length
          : 0;
        let strAccepted = `${acceptedItem} /${
          item.recipients ? item.recipients.length : 0
        }`;

        let downloadedItem = item.recipients
          ? item.recipients.filter((e: any) => {
              if (e.documents != null) {
                return e.documents.some((x: IReportDocument) => x.isDownloaded);
              } else {
                return false;
              }
            }).length
          : 0;
        let strDownloaded = `${downloadedItem} /${
          item.recipients ? item.recipients.length : 0
        }`;
        return (
          <Stack horizontal horizontalAlign="start">
            <TooltipHost
              content="Teams and conditions accepted"
              // This id is used on the tooltip itself, not the host
              // (so an element with this id only exists when the tooltip is shown)
              // id={tooltipArchiveId}
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <Icon iconName="CompletedIcon" className={iconClass} />
            </TooltipHost>

            <Label style={{ paddingTop: 3, fontWeight: 400 }}>
              {strAccepted}
            </Label>
            <TooltipHost
              content="Report downloaded"
              // This id is used on the tooltip itself, not the host
              // (so an element with this id only exists when the tooltip is shown)
              // id={tooltipArchiveId}
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <Icon iconName="DownloadIcon" className={iconClass} />
            </TooltipHost>
            <Label style={{ paddingTop: 3, fontWeight: 400 }}>
              {strDownloaded}
            </Label>
          </Stack>
        );
      },
    },
    {
      key: "column8",
      name: "",
      fieldName: "",
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      data: "string",
      isPadded: false,
      onRender: (item: any) => {
        return (
          <Stack horizontal horizontalAlign="end">
            <TooltipHost
              content="Edit/View project details"
              // This id is used on the tooltip itself, not the host
              // (so an element with this id only exists when the tooltip is shown)
              // id={tooltipArchiveId}
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <IconButton
                iconProps={{ iconName: "Edit" }}
                text=""
                label="Edit"
                onClick={() => {
                  navigate(`/project/${item.projectId}`);
                }}
                className={styles.actionIconButton}
                alt="Edit Project"
              />
            </TooltipHost>
            {item.status == "Archived" &&
              (item.owner?.includes(currentUser?.email) ||
                item.contributor?.includes(currentUser?.email)) && (
                <TooltipHost
                  content="Reactivate project"
                  // This id is used on the tooltip itself, not the host
                  // (so an element with this id only exists when the tooltip is shown)
                  // id={tooltipArchiveId}
                  calloutProps={calloutProps}
                  styles={hostStyles}
                >
                  <IconButton
                    iconProps={{
                      iconName: "PlaybackRate1xIcon",
                      styles: {
                        root: [
                          {
                            selectors: {
                              ">span": {
                                verticalAlign: "top",
                              },
                            },
                          },
                        ],
                      },
                    }}
                    text=""
                    onClick={() => {
                      isDeleteModalAction(false);
                      setProjectToReactive(item);
                      setModalText(
                        "Are you sure you want to reactivate this project?"
                      );
                      showModal();
                    }}
                    className={styles.actionIconButton}
                  />
                </TooltipHost>
              )}
            {item.status == "Active" &&
              (item.owner?.includes(currentUser?.email) ||
                item.contributor?.includes(currentUser?.email)) && (
                <TooltipHost
                  content="Archive project"
                  // This id is used on the tooltip itself, not the host
                  // (so an element with this id only exists when the tooltip is shown)
                  id={tooltipArchiveId}
                  calloutProps={calloutProps}
                  styles={hostStyles}
                >
                  <IconButton
                    iconProps={{
                      iconName: "ArchiveIcon",
                      styles: {
                        root: [
                          {
                            selectors: {
                              ">span": {
                                verticalAlign: "top",
                              },
                            },
                          },
                        ],
                      },
                    }}
                    onClick={() => {
                      isDeleteModalAction(false);
                      setProjectToArchive(item);
                      setModalText(
                        "Are you sure you want to archive this project?"
                      );
                      showModal();
                    }}
                    className={styles.actionIconButton}
                    aria-describedby={tooltipArchiveId}
                  />
                </TooltipHost>
              )}
            {item.status == "Draft" &&
              (item.owner?.includes(currentUser?.email) ||
                item.contributor?.includes(currentUser?.email)) && (
                <TooltipHost
                  content="Delete project"
                  // This id is used on the tooltip itself, not the host
                  // (so an element with this id only exists when the tooltip is shown)
                  // id={tooltipArchiveId}
                  calloutProps={calloutProps}
                  styles={hostStyles}
                >
                  <IconButton
                    iconProps={{ iconName: "Delete" }}
                    text=""
                    label="Delete"
                    onClick={() => {
                      isDeleteModalAction(true);
                      setProjectIdToDelete(item.projectId);
                      setModalText(
                        "Are you sure you want to delete this project?"
                      );
                      showModal();
                    }}
                    className={styles.actionIconButton}
                  />
                </TooltipHost>
              )}
          </Stack>
        );
      },
    },
  ];

  const getProjects = () => {
    service.GetAll().then((result: any) => {
      if (result) {
        setProjects(result);
      }
    });
  };

  const filterProject = () => {
    if (projects.length > 0) {
      let searchFiltred: IProject[] = [];
      if (searchValue != "") {
        searchFiltred = projects.filter((data) => {
          return (
            data.company.toLowerCase().includes(searchValue) ||
            data.target.toLowerCase().includes(searchValue) ||
            data.client.toLowerCase().includes(searchValue) ||
            data.name.toLowerCase().includes(searchValue) ||
            data.category.toLowerCase().includes(searchValue)
          );
        });
      } else {
        searchFiltred = projects;
      }

      if (statusFilter != "") {
        let result = searchFiltred.filter((data) => {
          return data.status == statusFilter;
        });
        if (result.length < pageSize) {
          setTotalPages(1);
        } else {
          setTotalPages(Math.ceil(result.length / pageSize));
        }
        setProjectsFiltred(
          result.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        );
      } else {
        if (searchFiltred.length < pageSize) {
          setTotalPages(1);
          setProjectsFiltred(searchFiltred);
        } else {
          setTotalPages(Math.ceil(searchFiltred.length / pageSize));
          setProjectsFiltred(
            searchFiltred.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize
            )
          );
        }
      }
    }
  };
  const handleLinkClick = (item?: PivotItem) => {
    setCurrentPage(1);
    if (item?.props.itemKey) {
      setStatusFilter(item?.props.itemKey);
    } else {
      setStatusFilter("");
    }
  };
  const changeCurrentPage = (newPageNumber: number) => {
    setCurrentPage(newPageNumber);
  };
  const onDeleteProject = () => {
    if (projectIdToDelete != "") {
      service.Delete(projectIdToDelete).then((result: any) => {
        if (result?.error) {
          setMessage(`Error: ${result?.error}`);
          setMessageBarType(MessageBarType.error);
        } else {
          setMessage(`Project deleted successfully`);
          setMessageBarType(MessageBarType.success);
          resetModal();
          getProjects();
        }
      });
    }
  };
  const onArchiveProject = () => {
    if (
      projectToArchive?.status != "Archived" &&
      projectToArchive != undefined
    ) {
      projectToArchive.status = "Archived";
      service.Update(projectToArchive as IProject).then((result: any) => {
        if (result?.error) {
          setMessage(`Error: ${result?.error}`);
          setMessageBarType(MessageBarType.error);
        } else {
          setMessage(`Project archived successfully`);
          setMessageBarType(MessageBarType.success);
          resetModal();
          getProjects();
        }
      });
    }
  };
  const onReactivateProject = () => {
    if (
      projectToReactivate?.status == "Archived" &&
      projectToReactivate != undefined
    ) {
      projectToReactivate.status = "Active";
      service.Update(projectToReactivate as IProject).then((result: any) => {
        if (result?.error) {
          setMessage(`Error: ${result?.error}`);
          setMessageBarType(MessageBarType.error);
        } else {
          setMessage(`Project reactivated successfully`);
          setMessageBarType(MessageBarType.success);
          resetModal();
          getProjects();
        }
      });
    }
  };
  const contentStyles = mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      border: "1px solid",
      minHeight: "120px",
      minWidth: "250px",
      borderRadius: "25px",
    },
  });
  const resetModal = () => {
    setProjectIdToDelete("");
    setProjectToArchive(undefined);
    setProjectToReactive(undefined);
    hideModal();
  };
  const getCompanies = () => {
    serviceCommon.GetAllCompanies().then((result: any) => {
      if (result.error) {
        setMessage(`Error: ${result?.error}`);
        setMessageBarType(MessageBarType.error);
      } else {
        setCompanies(result as ICommon[]);
      }
    });
  };
  useEffect(() => {
    // getCompanies();
    getProjects();
    const localCurrentUser = localStorage.getItem("currentUser");
    if (typeof localCurrentUser === "string") {
      setCurrentUser(JSON.parse(localCurrentUser));
    }
  }, []);
  useEffect(() => {
    filterProject();
  }, [currentPage, statusFilter, projects, searchValue]);

  return (
    <>
      <Stack verticalAlign="start">
        <StackItem align="start">
          <Label className={styles.PageHeader}>Project list</Label>
        </StackItem>
        <StackItem>
          <hr className={styles.horizontalLine}></hr>
        </StackItem>
        {message != "" && (
          <Stack>
            <MessageBar
              messageBarType={messageBarType}
              isMultiline={false}
              dismissButtonAriaLabel="Close"
              onDismiss={() => {
                setMessage("");
                setMessageBarType(undefined);
              }}
            >
              {message}
            </MessageBar>
          </Stack>
        )}
        {currentUser?.isProjectManager == true && (
          <StackItem align="end">
            <PrimaryButton
              text="New project"
              className={styles.buttonNewProject}
              onClick={routeChange}
            />
          </StackItem>
        )}
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Pivot onLinkClick={handleLinkClick}>
          <PivotItem headerText="Show all"> </PivotItem>
          <PivotItem headerText="Draft" itemKey="Draft">
            {" "}
          </PivotItem>
          <PivotItem headerText="Active" itemKey="Active">
            {" "}
          </PivotItem>
          <PivotItem headerText="Archived" itemKey="Archived">
            {" "}
          </PivotItem>
        </Pivot>
        <Stack horizontal>
          <SearchBox
            onSearch={(newValue) => {
              setSearchValue(newValue.toLowerCase());
            }}
            className={styles.searchBox}
            onClear={() => setSearchValue("")}
          />
        </Stack>
      </Stack>
      <Stack>
        <StackItem>
          <DetailsList
            items={projectsFiltred}
            compact={false}
            columns={columns}
            selectionMode={SelectionMode.none}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
          />
        </StackItem>
        <Sticky stickyPosition={StickyPositionType.Footer}>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onChange={changeCurrentPage}
          />
        </Sticky>
      </Stack>
      <Modal
        isOpen={isModalOpen}
        onDismiss={resetModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <Stack style={{ padding: 20 }}>
          <Label className={stylesNewProject.contentHeader}>{modalText}</Label>

          <Stack horizontal horizontalAlign="end">
            <PrimaryButton
              text="Confirm"
              className={stylesNewProject.buttonSave}
              disabled={false}
              onClick={
                deleteModalAction
                  ? onDeleteProject
                  : projectToReactivate
                  ? onReactivateProject
                  : onArchiveProject
              }
            />
            <PrimaryButton
              text="Cancel"
              className={stylesNewProject.buttonCancel}
              onClick={resetModal}
            />
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};
export default Home;
