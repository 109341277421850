import {
  Checkbox,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  ITextFieldStyles,
  ITooltipHostStyles,
  Icon,
  IconButton,
  Label,
  MessageBar,
  MessageBarType,
  Modal,
  PrimaryButton,
  SelectionMode,
  Stack,
  StackItem,
  Sticky,
  StickyPositionType,
  TextField,
  TooltipHost,
  mergeStyleSets,
} from "@fluentui/react";
import styles from "./companyManagement.module.scss";
import { useBoolean } from "@fluentui/react-hooks";
import { useEffect, useMemo, useState } from "react";
import Editor from "react-simple-wysiwyg";
// import { AcceptIcon } from "@fluentui/react-icons-mdl2";
import { ICompany } from "../../../../common/models/ICompany";
import { ITac } from "../../../../common/models/ITac";
import { ICommon } from "../../../../common/models/ICommon";
import CommonService from "../../../../common/api-services/CommonService";
import Pagination from "../../../common/pagination/Pagination";
import { useNavigate } from "react-router-dom";
const CompanyManagement = () => {
  const service = useMemo(() => new CommonService(), []);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [message, setMessage] = useState<string>("");
  const [messageBarType, setMessageBarType] = useState<MessageBarType>();
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [items, setItems] = useState<ICompany[]>([]);
  const [companyFiltered, setCompanyFiltred] = useState<ICompany[]>([]);
  const [commonItems, setCommonItems] = useState<ICommon[]>([]);
  const [edit, isEdit] = useState<boolean>(false);
  const [html, setHtml] = useState("");

  const [errorMessageFieldRequired, setErrorMessageFieldRequired] =
    useState<string>("");
  const [commonCompany, setCommonCompany] = useState<ICommon>({
    id: crypto.randomUUID(),
    typeId: "Company",
    company: { companyId: crypto.randomUUID() },
    tac: { tacId: "" },
  });
  const [commonTac, setCommonTac] = useState<ICommon>({
    id: crypto.randomUUID(),
    typeId: "TAC",
    company: { companyId: "" },
    tac: { tacId: crypto.randomUUID() },
  });
  let navigate = useNavigate();
  const calloutProps = { gapSpace: 0 };
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block" },
  };
  const contentStyles = mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      border: "1px solid",
      minHeight: "400px",
      minWidth: "600px",
      borderRadius: "25px",
      maxHeight: "auto",
    },
  });
  const textfieldStyles: Partial<ITextFieldStyles> = {
    root: {
      width: 350,
      paddingRight: 50,
      paddingBottom: 10,
    },
  };
  const columns: IColumn[] = [
    {
      key: "column1",
      name: "Code",
      fieldName: "companyCode",
      minWidth: 100,
      maxWidth: 200,
    },
    {
      key: "column2",
      name: "Name",
      fieldName: "companyName",
      minWidth: 100,
      maxWidth: 200,
    },
    {
      key: "column3",
      name: "Country",
      fieldName: "country",
      minWidth: 100,
      maxWidth: 150,
    },
    {
      key: "column4",
      name: "Active",
      fieldName: "active",
      minWidth: 60,
      maxWidth: 50,
      data: "boolean",
      onRender: (item: any) => {
        return (
          <>
            {item.active === true && (
              <Stack horizontalAlign="start" style={{ paddingLeft: 12 }}>
                <Icon iconName="AcceptIcon"></Icon>
              </Stack>
            )}
          </>
        );
      },
    },
    {
      key: "column8",
      name: "",
      fieldName: "",
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      data: "string",
      isPadded: false,
      onRender: (item: any) => {
        return (
          <Stack horizontal horizontalAlign="end">
            <TooltipHost
              content="Edit company"
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <IconButton
                iconProps={{ iconName: "Edit" }}
                className={styles.actionIconButton}
                onClick={() => {
                  onClickEditIcon(item);
                }}
              />
            </TooltipHost>
            {/* <TooltipHost
              content="Delete Company"
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <IconButton
                iconProps={{ iconName: "Delete" }}
                className={styles.actionIconButton}
              />
            </TooltipHost> */}
          </Stack>
        );
      },
    },
  ];

  function onChange(e: { target: { value: string } }) {
    setHtml(e.target.value);
    let obj = commonTac.tac;
    if (obj) {
      obj = { ...obj, tacContent: e.target.value };
      setCommonTac({ ...commonTac, tac: obj });
    }
  }
  const onChangeTxtField = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
    newValue?: string
  ): void => {
    let obj: any = commonCompany;
    if (obj.company) {
      obj.company[field] =
        field == "companyCode"
          ? newValue?.trim().replace(/[^a-zA-Z ]/g, "")
          : newValue;
      setCommonCompany({ ...commonCompany, company: obj.company });
    }
  };
  const onChangeCheckBox = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    let obj = commonCompany;
    if (obj.company) {
      obj.company.active = checked;
      setCommonCompany({ ...commonCompany, company: obj.company });
    }
  };
  const onSaveCompany = () => {
    let objCompany = commonCompany.company;
    let objTac = commonTac.tac;
    if (objCompany) objCompany.tacId = objTac?.tacId;
    if (objTac) {
      objTac.companyId = objCompany?.companyId;
      objTac.companyCode = objCompany?.companyCode;
      objTac.isCurrent = true;
      objTac.tacValidFrom = new Date();
    }
    let resCommonCompany = { ...commonCompany, company: objCompany };
    let resCommonTac = { ...commonTac, tac: objTac };
    if (
      resCommonCompany.company?.companyCode &&
      resCommonCompany.company.companyName &&
      resCommonCompany.company.country
    ) {
      setErrorMessageFieldRequired("");
      service
        .Create([resCommonCompany, resCommonTac] as ICommon[])
        .then((result: any) => {
          if (result?.error) {
            setMessageBarType(MessageBarType.error);
            setMessage(`Error: ${result?.error}`);
          } else {
            setMessageBarType(MessageBarType.success);
            setMessage(`Company Added successfully`);
            getCompanies();
          }
          resetModal();
        });
    } else {
      setErrorMessageFieldRequired("Field is required");
    }
  };
  const onEditCompany = () => {
    if (
      commonCompany.company?.companyCode &&
      commonCompany.company.companyName &&
      commonCompany.company.country
    ) {
      setErrorMessageFieldRequired("");
      service.Update(commonCompany).then((result: any) => {
        if (result?.error) {
          setMessageBarType(MessageBarType.error);
          setMessage(`Error: ${result?.error}`);
        } else {
          getCompanies();
          service.Update(commonTac).then((result: any) => {
            if (result?.error) {
              setMessageBarType(MessageBarType.error);
              setMessage(`Error: ${result?.error}`);
            } else {
              setMessageBarType(MessageBarType.success);
              setMessage(
                `Company and terms and conditions updated successfully`
              );
            }
          });
        }
        resetModal();
      });
    } else {
      setErrorMessageFieldRequired("Field is required");
    }
  };
  const changeCurrentPage = (newPageNumber: number) => {
    setCurrentPage(newPageNumber);
  };
  const getCompanies = () => {
    service.GetAllCompanies().then((data: any[]) => {
      if (data) {
        setCommonItems(data);
        let companies: ICompany[] = [];
        data.forEach((e) => {
          companies.push(e.company);
        });
        if (companies.length > 0) {
          setItems(companies);
        }
      }
    });
  };
  const resetModal = () => {
    hideModal();
    setCommonCompany({
      id: crypto.randomUUID(),
      typeId: "Company",
      company: { companyId: crypto.randomUUID() },
      tac: { tacId: "" },
    });
    setCommonTac({
      id: crypto.randomUUID(),
      typeId: "TAC",
      company: { companyId: "" },
      tac: { tacId: crypto.randomUUID() },
    });
    setHtml("");
  };

  const onClickEditIcon = (item: ICompany) => {
    let com = commonItems.find((x) => {
      return x.company?.companyId == item.companyId;
    });
    if (com) setCommonCompany(com);
    if (item.tacId) {
      service.GetTacbyTacId(item.tacId).then((data: any) => {
        if (data?.error) {
          setMessageBarType(MessageBarType.error);
          setMessage(`Error: ${data?.error}`);
        } else {
          console.log(data);
          isEdit(true);
          showModal();
          setCommonTac(data);

          if (data?.tac.tacContent) {
            setHtml(data?.tac.tacContent);
          }
        }
      });
    }
  };

  const filterCompanies = () => {
    if (items.length > 0) {
      if (items.length < pageSize) {
        setTotalPages(1);
      } else {
        setTotalPages(Math.ceil(items.length / pageSize));
      }
      setCompanyFiltred(
        items.slice((currentPage - 1) * pageSize, currentPage * pageSize)
      );
    }
  };

  useEffect(() => {
    getCompanies();
    const localCurrentUser = localStorage.getItem("currentUser");
    if (typeof localCurrentUser === "string") {
      const user = JSON.parse(localCurrentUser);
      if (
        user.isLegal == false ||
        user == undefined ||
        user.isLegal == undefined
      ) {
        navigate("/notfound");
      }
    }
  }, []);
  useEffect(() => {
    filterCompanies();
  }, [items, currentPage]);
  return (
    <>
      <Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Stack horizontal horizontalAlign="start">
            <Label className={styles.PageHeader}>Company management</Label>
          </Stack>
        </Stack>

        <Stack>
          <hr className={styles.horizontalLine}></hr>
        </Stack>
        {message != "" && (
          <Stack>
            <MessageBar
              messageBarType={messageBarType}
              isMultiline={true}
              dismissButtonAriaLabel="Close"
              onDismiss={() => {
                setMessage("");
                setMessageBarType(undefined);
              }}
            >
              {message}
            </MessageBar>
          </Stack>
        )}
        <Stack horizontal horizontalAlign="space-between">
          <Label className={styles.contentHeader}>
            Company and terms and conditions management
          </Label>

          <TooltipHost
            content="Add Company and Terms and Conditions"
            // This id is used on the tooltip itself, not the host
            // (so an element with this id only exists when the tooltip is shown)
            // id={tooltipArchiveId}
            calloutProps={calloutProps}
            styles={hostStyles}
          >
            <IconButton
              iconProps={{ iconName: "Add" }}
              className={styles.btnAddItem}
              onClick={showModal}
            ></IconButton>
          </TooltipHost>
        </Stack>
        <Stack>
          <StackItem>
            <DetailsList
              items={companyFiltered}
              compact={false}
              columns={columns}
              selectionMode={SelectionMode.none}
              setKey="none"
              layoutMode={DetailsListLayoutMode.fixedColumns}
              isHeaderVisible={true}
            />
          </StackItem>
          <Sticky stickyPosition={StickyPositionType.Footer}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onChange={changeCurrentPage}
            />
          </Sticky>
        </Stack>
      </Stack>
      <Modal
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <Stack style={{ padding: 20 }}>
          <Label className={styles.contentHeader}>
            Company and terms and conditions management
          </Label>
          <Stack>
            <hr className={styles.horizontalLine}></hr>
          </Stack>
          <Label className={styles.contentHeader}>Company</Label>
          <Stack horizontal horizontalAlign="start">
            <TextField
              label="Company name"
              value={commonCompany.company?.companyName}
              required
              styles={textfieldStyles}
              errorMessage={
                commonCompany.company?.companyName == "" ||
                commonCompany.company?.companyName == undefined
                  ? errorMessageFieldRequired
                  : undefined
              }
              onChange={(ev, val) => {
                onChangeTxtField(ev, "companyName", val);
              }}
            />
            <TextField
              label="Company code"
              value={commonCompany.company?.companyCode}
              required
              styles={textfieldStyles}
              errorMessage={
                commonCompany.company?.companyCode == "" ||
                commonCompany.company?.companyCode == undefined
                  ? errorMessageFieldRequired
                  : undefined
              }
              onChange={(ev, val) => {
                onChangeTxtField(ev, "companyCode", val);
              }}
            />
            <TextField
              label="Country"
              value={commonCompany.company?.country}
              required
              styles={textfieldStyles}
              errorMessage={
                commonCompany.company?.country == "" ||
                commonCompany.company?.country == undefined
                  ? errorMessageFieldRequired
                  : undefined
              }
              onChange={(ev, val) => {
                onChangeTxtField(ev, "country", val);
              }}
            />
            <Checkbox
              label="Active"
              styles={{ root: { alignItems: "end", paddingBottom: 12 } }}
              onChange={onChangeCheckBox}
              checked={commonCompany.company?.active}
            ></Checkbox>
          </Stack>
          <Stack horizontal horizontalAlign="start">
            <TextField
              label="Address"
              value={commonCompany.company?.address}
              styles={textfieldStyles}
              errorMessage={
                commonCompany.company?.address == "" ||
                commonCompany.company?.address == undefined
                  ? errorMessageFieldRequired
                  : undefined
              }
              onChange={(ev, val) => {
                onChangeTxtField(ev, "address", val);
              }}
            />
            <TextField
              label="Zip"
              value={commonCompany.company?.zip}
              styles={textfieldStyles}
              errorMessage={
                commonCompany.company?.zip == "" ||
                commonCompany.company?.zip == undefined
                  ? errorMessageFieldRequired
                  : undefined
              }
              onChange={(ev, val) => {
                onChangeTxtField(ev, "zip", val);
              }}
            />
            <TextField
              label="City"
              value={commonCompany.company?.city}
              styles={textfieldStyles}
              errorMessage={
                commonCompany.company?.city == "" ||
                commonCompany.company?.city == undefined
                  ? errorMessageFieldRequired
                  : undefined
              }
              onChange={(ev, val) => {
                onChangeTxtField(ev, "city", val);
              }}
            />
          </Stack>
          <Stack>
            <hr className={styles.horizontalLine}></hr>
          </Stack>
          <Label className={styles.contentHeader}>Terms and conditions</Label>
          <Stack>
            <Editor
              value={html}
              onChange={onChange}
              style={{ maxHeight: 300, overflow: "auto" }}
            />
          </Stack>
          <Stack horizontal horizontalAlign="end" style={{ paddingTop: 20 }}>
            <PrimaryButton
              text={!edit ? "Add" : "Edit"}
              className={styles.buttonSave}
              disabled={false}
              onClick={!edit ? onSaveCompany : onEditCompany}
            />
            <PrimaryButton
              text="Cancel"
              className={styles.buttonCancel}
              onClick={hideModal}
            />
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};
export default CompanyManagement;
