import { Routes, Route } from "react-router-dom";

import Home from "../pages/home/home";
import NewProject from "../pages/newProject/newProject";
import NotFound from "../pages/not-found/NotFound";

import CompanyManagement from "../pages/admin/companyManagement/companyManagement";
import UserManagement from "../pages/admin/userManagement/userManagement";

const AppRouting: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/home" element={<Home />}></Route>
        <Route path="/project" element={<NewProject />}></Route>
        <Route path="/project/:projectId" element={<NewProject />}></Route>
        <Route path="/usermanagement" element={<UserManagement />}></Route>
        <Route
          path="/companymanagement"
          element={<CompanyManagement />}
        ></Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};
export default AppRouting;
