import { IPermission } from "../models/IPermission";
import axiosHelper from "./AxiosApi";
export default class PermissionService {
  public GetById(PermissionId: string): Promise<IPermission> {
    return axiosHelper.get(`/Permission/GetById/${PermissionId}`);
  }
  public GetAll(): Promise<IPermission[]> {
    return axiosHelper.get("/Permission/GetAll");
  }
  public Create(input: IPermission[]): Promise<IPermission> {
    return axiosHelper.post("/Permission/Create", input);
  }
  public Update(input: IPermission): Promise<IPermission> {
    return axiosHelper.put("/Permission/Update", input);
  }
  public Delete(permissionId: string): Promise<IPermission> {
    return axiosHelper.delete(`/Permission/Delete/${permissionId}`);
  }
  public GetByUserEmail(email: string): Promise<IPermission> {
    return axiosHelper.get(`/Permission/GetByUserEmail/${email}`);
  }
}
