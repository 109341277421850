import axios from "axios";

import { getToken } from "../authProvider/authProvider";
import GraphService from "../authProvider/GraphService";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

instance.interceptors.request.use((config) => {
  var graphService = new GraphService();
  var graphUserPromise = graphService.getAzureCurrentUser();
  var windowsToken = getToken();
  return Promise.all([graphUserPromise, windowsToken]).then(
    (data) => {
      config.headers["Authorization"] = `Bearer ${data[1]}`;
      config.headers["UserEmail"] = data[0].email || "";
      config.headers["UserdisplayName"] = data[0].displayName || "";
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
});

instance.interceptors.response.use((response) => {
  return response.data;
});

export default instance;
