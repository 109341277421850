import {
  Checkbox,
  ChoiceGroup,
  DetailsList,
  DetailsListLayoutMode,
  IChoiceGroupOption,
  IColumn,
  IPersonaProps,
  ITooltipHostStyles,
  Icon,
  IconButton,
  Label,
  MessageBar,
  MessageBarType,
  Modal,
  NormalPeoplePicker,
  Persona,
  PersonaSize,
  PrimaryButton,
  SelectionMode,
  Stack,
  StackItem,
  Sticky,
  StickyPositionType,
  TooltipHost,
  mergeStyleSets,
  mergeStyles,
} from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";

import { useNavigate } from "react-router-dom";
import GraphService from "../../../../common/authProvider/GraphService";
import styles from "./userManagement.module.scss";
import React from "react";
import PermissionService from "../../../../common/api-services/PermissionService";
import { IPermission } from "../../../../common/models/IPermission";
import Pagination from "../../../common/pagination/Pagination";
import { IAzureUserInformation } from "../../../../common/models/IUserDetails";

const UserManagement = () => {
  const calloutProps = { gapSpace: 0 };
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block" },
  };

  const contentStyles = mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      border: "1px solid",
      minHeight: "400px",
      minWidth: "600px",
      borderRadius: "25px",
    },
    archiveContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      border: "1px solid",
      minHeight: "120px",
      minWidth: "250px",
      borderRadius: "25px",
    },
  });
  const [currentUser, setCurrentUser] = useState<IAzureUserInformation>();
  const [deletePermission, isDeletePermission] = useState<boolean>(false);
  const [deletePermissionItem, setDeletePermissionItem] = useState<any>();
  const service = useMemo(() => new PermissionService(), []);
  const graphService = useMemo(() => new GraphService(), []);
  const [users, setUsers] = useState<any>([]);
  const [itemsAccessManagement, setItemsAccessManagement] = useState<any[]>([]);
  const [usersPaginated, setUsersPaginated] = useState<any[]>([]);
  const [editPermission, isEditPermission] = useState<boolean>(false);
  const [editOwnAdminPermission, isEditOwnAdminPermission] =
    useState<boolean>(false);
  const [messagePermission, setMessagePermission] = useState<string>("");
  const [messageBarTypePermission, setMessageBarTypePermission] =
    useState<MessageBarType>();
  const [itemsAccessManagementModal, setItemsAccessManagementModal] = useState<
    any[]
  >([]);

  const [peopleList, setPeopleList] = React.useState<IPersonaProps[]>([]);

  const [currentSelectedMembers, setCurrentSelectedMembers] = React.useState<
    IPersonaProps[]
  >([]);

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  let navigate = useNavigate();

  function doesTextStartWith(text: string, filterText: string): boolean {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
  }
  function listContainsPersona(
    persona: IPersonaProps,
    personas: IPersonaProps[]
  ) {
    if (!personas || !personas.length || personas.length === 0) {
      return false;
    }
    return personas.filter((item) => item.text === persona.text).length > 0;
  }
  function removeDuplicates(
    personas: IPersonaProps[],
    possibleDupes: IPersonaProps[]
  ) {
    return personas.filter(
      (persona) => !listContainsPersona(persona, possibleDupes)
    );
  }
  const filterPersonasByText = (filterText: string): IPersonaProps[] => {
    return peopleList.filter((item) =>
      doesTextStartWith(item.text as string, filterText)
    );
  };
  const filterPromise = (
    personasToReturn: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return personasToReturn;
  };
  const onItemsChange = (items?: any[]): void => {
    let result: IPersonaProps[] = [];
    let accessItems: any[] = [];
    if (items) {
      items.forEach((element) => {
        let temp = users.filter((item: { mail: string }) => {
          return (item.mail as string) == element.key;
        });
        result.push(temp[0]);
        let accessItem = itemsAccessManagementModal.filter((item: any) => {
          return item.mail == temp[0].mail;
        });
        if (accessItem.length == 0) {
          accessItem = {
            ...temp[0],
            isAdmin: false,
            isLegal: false,
            isProjectManager: false,
            permissionId: crypto.randomUUID(),
          };
          accessItems.push(accessItem);
        } else {
          accessItems.push(accessItem[0]);
        }
      });
      setItemsAccessManagementModal(accessItems);
    }
  };
  const onFilterChanged = (
    filterText: string,
    currentPersonas?: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);
      let currentSelectedUsers: IPersonaProps[] = [];

      currentPersonas = currentSelectedMembers;
      filteredPersonas = removeDuplicates(
        filteredPersonas,
        currentPersonas ?? []
      );
      filteredPersonas = limitResults
        ? filteredPersonas.slice(0, limitResults)
        : filteredPersonas;
      return filterPromise(filteredPersonas);
    } else {
      return [];
    }
  };

  const columnStyle = mergeStyles({
    alignSelf: "center",
  });

  const columnsAccessManagement: IColumn[] = [
    {
      key: "column1",
      name: "Name",
      fieldName: "displayName",
      minWidth: 100,
      maxWidth: 200,
    },
    {
      key: "column2",
      name: "E-mail",
      fieldName: "mail",
      minWidth: 80,
      maxWidth: 300,
      isRowHeader: true,
      data: "string",
    },
    {
      key: "column3",
      name: "Admin",
      fieldName: "isAdmin",
      minWidth: 60,
      maxWidth: 60,
      isRowHeader: true,
      data: "boolean",
      isPadded: false,
      onRender: (item: any) => {
        return (
          <>
            {item.isAdmin === true && (
              <Stack horizontalAlign="center">
                <Icon iconName="AcceptIcon"></Icon>
              </Stack>
            )}
          </>
        );
      },
    },
    {
      key: "column4",
      name: "Legal",
      fieldName: "isLegal",
      minWidth: 100,
      maxWidth: 40,
      isRowHeader: true,
      data: "boolean",
      isPadded: false,
      onRender: (item: any) => {
        return (
          <>
            {item.isLegal === true && (
              <Stack horizontalAlign="center">
                <Icon iconName="AcceptIcon"></Icon>
              </Stack>
            )}
          </>
        );
      },
    },
    {
      key: "column5",
      name: "Project manager",
      fieldName: "isProjectManager",
      minWidth: 80,
      maxWidth: 110,
      isRowHeader: true,

      data: "boolean",
      isPadded: false,
      onRender: (item: any) => {
        return (
          <>
            {item.isProjectManager === true && (
              <Stack horizontalAlign="center">
                <Icon iconName="AcceptIcon"></Icon>
              </Stack>
            )}
          </>
        );
      },
    },
    {
      key: "column8",
      name: "",
      fieldName: "",
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      data: "string",
      isPadded: false,
      onRender: (item: any) => {
        return (
          <Stack horizontal horizontalAlign="end">
            <TooltipHost
              content="Edit user permission"
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <IconButton
                iconProps={{ iconName: "Edit" }}
                className={styles.actionIconButton}
                onClick={() => {
                  isEditPermission(true);
                  setItemsAccessManagementModal([item]);
                  showModal();
                }}
              />
            </TooltipHost>
            <TooltipHost
              content="Delete user permission"
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <IconButton
                iconProps={{ iconName: "Delete" }}
                className={styles.actionIconButton}
                onClick={() => {
                  isDeletePermission(true);
                  setDeletePermissionItem(item);
                  showModal();
                }}
              />
            </TooltipHost>
          </Stack>
        );
      },
    },
  ];
  const columnsAccessManagementModal: IColumn[] = [
    {
      key: "column1",
      name: "",
      fieldName: "text",
      minWidth: 100,
      maxWidth: 150,
      className: columnStyle,
      onRender: (item: any) => {
        return (
          <Stack horizontal horizontalAlign="start">
            <Persona
              text={item.displayName}
              secondaryText={item.jobTitle}
              size={PersonaSize.size32}
            />
          </Stack>
        );
      },
    },
    {
      key: "column5",
      name: "Role",
      fieldName: "",
      minWidth: 80,
      maxWidth: 150,
      isRowHeader: true,

      data: "string",
      isPadded: false,
      onRender: (item: any) => {
        return (
          <Stack horizontal horizontalAlign="start">
            <Checkbox
              label="Admin"
              onChange={(ev, checked) => {
                onChangeCheckBox(item, "Admin", ev, checked);
              }}
              checked={item.isAdmin}
              styles={{ root: { margin: 5 } }}
            />
            <Checkbox
              label="Legal"
              checked={item.isLegal}
              disabled={item.isProjectManager}
              onChange={(ev, checked) => {
                onChangeCheckBox(item, "Legal", ev, checked);
              }}
              styles={{ root: { margin: 5 } }}
            />
            <Checkbox
              label="Project Manager"
              onChange={(ev, checked) => {
                onChangeCheckBox(item, "Project Manager", ev, checked);
              }}
              checked={item.isProjectManager}
              disabled={item.isLegal}
              styles={{ root: { margin: 5 } }}
            />
          </Stack>
        );
      },
    },
  ];
  const onChangeCheckBox = (
    item: any,
    field: string,
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    let accessItems: any[] = [];

    itemsAccessManagementModal.forEach((element) => {
      if (element.mail === item.mail) {
        if (checked) {
          if (field === "Admin") {
            element.isAdmin = true;
          }
          if (field === "Legal") {
            element.isLegal = true;
          }
          if (field === "Project Manager") {
            element.isProjectManager = true;
          }
        } else {
          if (field === "Admin") {
            element.isAdmin = false;
          }
          if (field === "Legal") {
            element.isLegal = false;
          }
          if (field === "Project Manager") {
            element.isProjectManager = false;
          }
        }
        accessItems.push(element);
      } else {
        accessItems.push(element);
      }
    });
    setItemsAccessManagementModal(accessItems);
  };
  const onAddPermission = () => {
    let listToAdd: any[] = [];
    let listToUpdate: any[] = [];
    itemsAccessManagementModal.forEach((item: any) => {
      let exist = itemsAccessManagement.find((e) => e.mail === item.mail);
      if (exist) {
        item.permissionId = exist.permissionId;
        listToUpdate.push(item);
      } else {
        listToAdd.push(item);
      }
    });
    console.log(`listToAdd: ${listToAdd}`);
    console.log(`listToUpdate: ${listToUpdate}`);
    let mes = "";
    let error = "";
    let i = 0;
    if (listToAdd.length > 0) {
      service.Create(listToAdd as IPermission[]).then((result: any) => {
        if (result?.error) {
          error += `Error: ${result?.error}`;
        } else {
          mes = `${listToAdd.length} Permissions created successfully.`;
          if (listToUpdate.length > 0) {
            let i = 0;
            listToUpdate.forEach((element) => {
              service.Update(element as IPermission).then((result: any) => {
                i++;
                if (result?.error) {
                  error += `Error: ${result?.error}`;
                }
                if (i == listToUpdate.length) {
                  if (error != "") {
                    setMessageBarTypePermission(MessageBarType.success);
                    setMessagePermission(`${error} ${mes}`);
                  } else {
                    mes = `${mes} ${i} Permissions updated successfully`;
                    setMessageBarTypePermission(MessageBarType.success);
                    setMessagePermission(mes);
                  }
                  resetModal();
                  getPermissions();
                }
              });
            });
          } else {
            if (error != "") {
              setMessageBarTypePermission(MessageBarType.error);
              setMessagePermission(`${error} ${mes}`);
            } else {
              setMessageBarTypePermission(MessageBarType.success);
              setMessagePermission(mes);
            }
            resetModal();
            getPermissions();
          }
        }
      });
    } else {
      if (listToUpdate.length > 0) {
        listToUpdate.forEach((element) => {
          service.Update(element as IPermission).then((result: any) => {
            i++;
            if (result?.error) {
              error += `Error: ${result?.error}`;
            }
            if (i == listToUpdate.length) {
              if (error != "") {
                setMessageBarTypePermission(MessageBarType.success);
                setMessagePermission(`${error} ${mes}`);
              } else {
                mes = `${i} Permissions updated successfully`;
                setMessageBarTypePermission(MessageBarType.success);
                setMessagePermission(mes);
              }
              resetModal();
              getPermissions();
            }
          });
        });

        resetModal();
        getPermissions();
      }
    }
  };
  const onEditPermission = () => {
    if (itemsAccessManagementModal.length == 1) {
      let itemToUpdate = itemsAccessManagementModal[0];
      service.Update(itemToUpdate as IPermission).then((result: any) => {
        if (result?.error) {
          setMessagePermission(`Error: ${result?.error}`);
          setMessageBarTypePermission(MessageBarType.error);
        } else {
          setMessagePermission(`Permission updated successfully`);
          setMessageBarTypePermission(MessageBarType.success);
          resetModal();
          getPermissions();
        }
      });
    }
  };
  const checkOwnRole = () => {
    if (itemsAccessManagementModal.length == 1) {
      let itemToUpdate = itemsAccessManagementModal[0] as IPermission;
      if (itemToUpdate.mail == currentUser?.email && !itemToUpdate.isAdmin) {
        isEditOwnAdminPermission(true);
      } else {
        onEditPermission();
      }
    }
  };
  const onDeletePermission = (item: any) => {
    service.Delete(item.permissionId).then((result: any) => {
      if (result?.error) {
        setMessagePermission(`Error: ${result?.error}`);
        setMessageBarTypePermission(MessageBarType.error);
      } else {
        setMessagePermission(`Permission deleted successfully`);
        setMessageBarTypePermission(MessageBarType.success);
        resetModal();
        getPermissions();
      }
    });
  };
  const changeCurrentPage = (newPageNumber: number) => {
    setCurrentPage(newPageNumber);
  };
  const resetModal = () => {
    hideModal();
    isEditPermission(false);
    isEditOwnAdminPermission(false);
    setItemsAccessManagementModal([]);
    isDeletePermission(false);
    setDeletePermissionItem(null);
    getPermissions();
  };

  const getUsers = () => {
    graphService.getUsers().then((data: any) => {
      setUsers(data.value);
      let peoples: IPersonaProps[] = [];
      data.value.forEach((element: { [x: string]: any }) => {
        let option = {
          key: element["mail"] || 0,
          text: element["displayName"] || "",
        };
        peoples.push(option);
      });
      setPeopleList(peoples);
    });
  };
  const getPermissions = () => {
    service.GetAll().then((result: any) => {
      if (result) {
        setItemsAccessManagement(result);
        let currentSelectedUsers: IPersonaProps[] = [];
        result.forEach((element: { [x: string]: any }) => {
          let option = {
            key: element["mail"] || 0,
            text: element["displayName"] || "",
          };
          currentSelectedUsers.push(option);
        });
        setCurrentSelectedMembers(currentSelectedUsers);
      }
    });
  };
  const filterPermissions = () => {
    if (itemsAccessManagement.length > 0) {
      if (itemsAccessManagement.length < pageSize) {
        setTotalPages(1);
      } else {
        setTotalPages(Math.ceil(itemsAccessManagement.length / pageSize));
      }
      setUsersPaginated(
        itemsAccessManagement.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize
        )
      );
    }
  };
  useEffect(() => {
    getUsers();
    getPermissions();
    const localCurrentUser = localStorage.getItem("currentUser");
    if (typeof localCurrentUser === "string") {
      const user = JSON.parse(localCurrentUser);
      setCurrentUser(user);

      if (
        user.isAdmin == false ||
        user == undefined ||
        user.isAdmin == undefined
      ) {
        navigate("/notfound");
      }
    }
  }, []);
  useEffect(() => {
    filterPermissions();
  }, [itemsAccessManagement, currentPage]);

  return (
    <>
      <Stack>
        <Stack horizontal horizontalAlign="space-between">
          <Stack horizontal horizontalAlign="start">
            <Label className={styles.PageHeader}>User management</Label>
          </Stack>
        </Stack>

        <Stack>
          <hr className={styles.horizontalLine}></hr>
        </Stack>
        {messagePermission != "" && (
          <Stack>
            <MessageBar
              messageBarType={messageBarTypePermission}
              isMultiline={true}
              dismissButtonAriaLabel="Close"
              onDismiss={() => {
                setMessagePermission("");
                setMessageBarTypePermission(undefined);
              }}
            >
              {messagePermission}
            </MessageBar>
          </Stack>
        )}
        <Stack horizontal horizontalAlign="space-between">
          <Label className={styles.contentHeader}>
            Global access management
          </Label>

          <TooltipHost
            content="Add user permission"
            // This id is used on the tooltip itself, not the host
            // (so an element with this id only exists when the tooltip is shown)
            // id={tooltipArchiveId}
            calloutProps={calloutProps}
            styles={hostStyles}
          >
            <IconButton
              iconProps={{ iconName: "Add" }}
              className={styles.btnAddItem}
              onClick={showModal}
            ></IconButton>
          </TooltipHost>

          <Modal
            isOpen={isModalOpen}
            onDismiss={resetModal}
            isBlocking={false}
            containerClassName={
              deletePermission || editOwnAdminPermission
                ? contentStyles.archiveContainer
                : contentStyles.container
            }
          >
            {!deletePermission && !editOwnAdminPermission && (
              <Stack style={{ padding: 20 }}>
                <Label className={styles.contentHeader}>
                  Global access management -{" "}
                  {!editPermission
                    ? "add user permission"
                    : "edit user permission"}
                </Label>
                {!editPermission && (
                  <NormalPeoplePicker
                    onChange={onItemsChange}
                    onResolveSuggestions={onFilterChanged}
                    // getTextFromItem={getTextFromItem}
                    // pickerSuggestionsProps={suggestionProps}
                    className={"ms-PeoplePicker"}
                    key={"normal"}
                    // eslint-disable-next-line react/jsx-no-bind
                    selectionAriaLabel={"Selected contacts"}
                    removeButtonAriaLabel={"Remove"}
                    resolveDelay={300}
                  />
                )}

                <DetailsList
                  items={itemsAccessManagementModal}
                  compact={false}
                  columns={columnsAccessManagementModal}
                  selectionMode={SelectionMode.none}
                  setKey="none"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                />
                <Stack horizontal horizontalAlign="end">
                  <PrimaryButton
                    text={!editPermission ? "Add" : "Edit"}
                    className={styles.buttonSave}
                    disabled={false}
                    onClick={!editPermission ? onAddPermission : checkOwnRole}
                  />
                  <PrimaryButton
                    text="Cancel"
                    className={styles.buttonCancel}
                    onClick={resetModal}
                  />
                </Stack>
              </Stack>
            )}
            {(deletePermission || editOwnAdminPermission) && (
              <Stack style={{ padding: 20 }}>
                {deletePermission && (
                  <Label className={styles.contentHeader}>
                    Are you sure you want to delete{" "}
                    {deletePermissionItem.displayName}?
                  </Label>
                )}
                {editOwnAdminPermission && (
                  <Label className={styles.contentHeader}>
                    Are you sure you want to remove your admin role?
                  </Label>
                )}
                <Stack horizontal horizontalAlign="end">
                  <PrimaryButton
                    text="Confirm"
                    className={styles.buttonSave}
                    disabled={false}
                    onClick={() =>
                      editOwnAdminPermission
                        ? onEditPermission()
                        : onDeletePermission(deletePermissionItem)
                    }
                  />
                  <PrimaryButton
                    text="Cancel"
                    className={styles.buttonCancel}
                    onClick={resetModal}
                  />
                </Stack>
              </Stack>
            )}
          </Modal>
        </Stack>
        <Stack>
          <StackItem>
            <DetailsList
              items={usersPaginated}
              compact={false}
              columns={columnsAccessManagement}
              selectionMode={SelectionMode.none}
              setKey="none"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
            />
          </StackItem>
          <Sticky stickyPosition={StickyPositionType.Footer}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onChange={changeCurrentPage}
            />
          </Sticky>
        </Stack>
      </Stack>
    </>
  );
};
export default UserManagement;
