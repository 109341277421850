import { IReportDocument } from "../models/IReportDocument";
import { IProject } from "../models/IProject";
import axiosHelper from "./AxiosApi";
export default class ProjectService {
  public GetById(projectId: string): Promise<IProject> {
    return axiosHelper.get(`/Project/GetById/${projectId}`);
  }
  public GetAll(): Promise<IProject[]> {
    return axiosHelper.get("/Project/GetAll");
  }
  public Create(input: IProject): Promise<IProject> {
    return axiosHelper.post("/Project/Create", input);
  }
  public Update(input: IProject): Promise<IProject> {
    return axiosHelper.put("/Project/Update", input);
  }
  public Delete(projectId: string): Promise<IProject> {
    return axiosHelper.delete(`/Project/Delete/${projectId}`);
  }
  public StartProcess(input: IProject): Promise<IProject> {
    return axiosHelper.put("/Project/StartProcess", input);
  }
  public SendNotificationEmailToRecipient(input: IProject): Promise<IProject> {
    return axiosHelper.post("/Project/SendNotificationEmailToRecipient", input);
  }
  public SendReportEmailToRecipient(input: IProject): Promise<IProject> {
    return axiosHelper.post("/Project/SendReportEmailToRecipient", input);
  }
  public UploadReport(input: IProject): Promise<IProject> {
    return axiosHelper.post("/Project/UploadReport", input);
  }
  public DownloadReport(input: IProject): Promise<string> {
    return axiosHelper.post(`/Project/DownloadReport/`, input);
  }
  public DeleteRecipient(
    projectId: string,
    recipientId: string
  ): Promise<IProject> {
    return axiosHelper.delete(
      `/Project/DeleteRecipient/${projectId}/${recipientId}`
    );
  }
  public DeleteUserPermission(
    input: IProject,
    deleteUserEmail: string
  ): Promise<IProject> {
    return axiosHelper.post(
      `/Project/DeleteUserPermission?deleteUserEmail=${deleteUserEmail}`,
      input
    );
  }
  public UploadDocument(
    input: IReportDocument,
    projectId: string
  ): Promise<IProject> {
    return axiosHelper.post(
      `/Project/UploadDocument?projectId=${projectId}`,
      input
    );
  }
  public DeleteDocument(
    input: IReportDocument,
    projectId: string
  ): Promise<IProject> {
    return axiosHelper.post(
      `/Project/DeleteDocument?projectId=${projectId}`,
      input
    );
  }
  public DownloadDocument(fileLink: string): Promise<string> {
    return axiosHelper.post(`/Project/DownloadDocument?fileLink=${fileLink}`);
  }
  public DisableDocument(
    fileName: string,
    projectId: string,
    disable: boolean
  ): Promise<string> {
    return axiosHelper.post(
      `/Project/DisableDocument?fileName=${fileName}&projectId=${projectId}&disable=${disable}`
    );
  }
}
