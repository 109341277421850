import { ICommon } from "../models/ICommon";
import axiosHelper from "./AxiosApi";
export default class CommonService {
  public GetById(PermissionId: string): Promise<ICommon> {
    return axiosHelper.get(`/Permission/GetById/${PermissionId}`);
  }
  public GetAllCompanies(): Promise<ICommon[]> {
    return axiosHelper.get("/Common/GetAllCompanies");
  }
  public Create(input: ICommon[]): Promise<ICommon> {
    return axiosHelper.post("/Common/Create", input);
  }

  public Update(input: ICommon): Promise<ICommon> {
    return axiosHelper.put("/Common/Update", input);
  }
  // public Delete(permissionId: string): Promise<ICommon> {
  //   return axiosHelper.delete(`/Common/Delete/${permissionId}`);
  // }
  public GetTacbyTacId(id: string): Promise<ICommon> {
    return axiosHelper.get(`/Common/GetTacbyTacId/${id}`);
  }
  public GetAllActiveCompanies(): Promise<ICommon[]> {
    return axiosHelper.get("/Common/GetAllActiveCompanies");
  }
}
