import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import reportWebVitals from "./reportWebVitals";
import { AzureAD, } from "react-aad-msal";
import { authProvider } from "./common/authProvider/authProvider";


initializeIcons();

ReactDOM.render(
  
    <AzureAD provider={authProvider} forceLogin={true} >
       <React.StrictMode>
        <App />  
      </React.StrictMode> 
     </AzureAD> 
  ,
    document.getElementById('root')
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
