import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { UserAgentApplication } from "msal";

const clientId = process.env.REACT_APP_CLIENT_ID as string;
const tenantId = process.env.REACT_APP_TENANT_ID as string;
// Msal Configurations
export const config = {
  auth: {
    authority: `https://login.microsoftonline.com/${tenantId}`,
    clientId: clientId,
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,

    //  validateAuthority: true,
    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: true,
  },
  cache: {
    //cacheLocation: cachelo,
    storeAuthStateInCookie: true,
  },
};

export const graphConfiguration = {
  auth: {
    authority: `https://graph.microsoft.com/v1.0/${tenantId}`,
    clientId: clientId,
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    validateAuthority: true,
    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: true,
  },
  cache: {
    storeAuthStateInCookie: true,
  },
};
// Authentication Parameters
export const authenticationParameters = {
  scopes: [`${clientId}/.default`],
};

export const authenticationParametersGraph = {
  scopes: ["openid", "profile", "user.read"],
};

// Options
export const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin,
};
export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
export const msalApp = new UserAgentApplication(config);

export const getToken = () => {
  const account = msalApp.getAccount();
  return (account && msalApp.acquireTokenSilent(authenticationParameters)).then(
    (token) => {
      return token.accessToken;
    }
  );
};
