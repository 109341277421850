import { authenticationParametersGraph, msalApp } from "./authProvider";
import { IAzureUserInformation } from "../models/IUserDetails";
import ConfigurationService from "../api-services/ConfigurationService";

export default class GraphService {
  private graph;
  public token: string = "";
  private graphClient: any;

  constructor() {
    this.graph = require("@microsoft/microsoft-graph-client");
  }

  private getAuthenticatedClient(accessToken: string) {
    const client = this.graph.Client.init({
      authProvider: (done: any) => {
        done(null, accessToken);
      },
    });

    return client;
  }

  private getUserInformation = () => {
    const account = msalApp.getAccount();
    return (
      account && msalApp.acquireTokenSilent(authenticationParametersGraph)
    ).then((token) => {
      this.token = token.accessToken;
      return this.getUserDetails(token.accessToken);
    });
  };

  private getUserDetails = (accessToken: string) => {
    var client = this.getAuthenticatedClient(accessToken);
    this.graphClient = client;
    return client.api("/me").get();
  };

  public getAzureCurrentUser = (): Promise<IAzureUserInformation> => {
    let loggedUser: IAzureUserInformation;
    return this.getUserInformation().then((user) => {
      loggedUser = {
        uid: user.id,
        displayName: user.displayName,
        userPrincipalName: user.userPrincipalName,
        email: user.mail,
      };
      return loggedUser;
    });
  };

  private getUserPicture = () => {
    var client = this.graphClient;
    return client.api("/me/photo/$value").get();
  };

  public getUsers = (): Promise<IAzureUserInformation[]> => {
    const account = msalApp.getAccount();
    const service = new ConfigurationService();

    return (
      account && msalApp.acquireTokenSilent(authenticationParametersGraph)
    ).then((token) => {
      this.token = token.accessToken;
      var client = this.getAuthenticatedClient(this.token);
      this.graphClient = client;
      let users = service.GetAppSettings().then((settings: any) => {
        localStorage.setItem("appSettings", JSON.stringify(settings));
        return client
          .api(
            `/groups/${settings?.possibleProjectMembers}/transitiveMembers?$select=displayName,userPrincipalName,jobTitle,department,mail&$top=999`
          )
          .get();
      });
      return users;
    });
  };

  public getUsersByName = (
    searchString: string
  ): Promise<IAzureUserInformation[]> => {
    const account = msalApp.getAccount();
    const service = new ConfigurationService();

    return (
      account && msalApp.acquireTokenSilent(authenticationParametersGraph)
    ).then((token) => {
      this.token = token.accessToken;
      var client = this.getAuthenticatedClient(this.token);
      this.graphClient = client;
      let users = service.GetAppSettings().then((settings: any) => {
        localStorage.setItem("appSettings", JSON.stringify(settings));
        return client
          .api(
            `/groups/${settings?.possibleProjectMembers}/transitiveMembers?$select=displayName,userPrincipalName,jobTitle,department,mail&$count=true&$filter=startswith(DisplayName, '${searchString}') or startswith(Mail, '${searchString}')`
          )
          .headers({
            ConsistencyLevel: "eventual",
          })
          .get();
      });
      return users;
    });
  };

  public getUserBatch = async (userEmails: string[]): Promise<any[]> => {
    const account = msalApp.getAccount();
    const service = new ConfigurationService();

    let arrayOfRequests: any[] = [];
    let i = 1;
    userEmails.map((u) => {
      if (u != "") {
        let obj: any = {
          id: `${i}`,
          url: `users?$select=displayName,userPrincipalName,jobTitle,department,mail&$filter=mail eq '${u}'&$count=true`,
          method: "GET",
          headers: {
            ConsistencyLevel: "eventual",
          },
        };
        arrayOfRequests.push(obj);
        i++;
      }
    });

    let finalObj = {
      requests: arrayOfRequests,
    };

    return (
      account && msalApp.acquireTokenSilent(authenticationParametersGraph)
    ).then((token) => {
      this.token = token.accessToken;
      var client = this.getAuthenticatedClient(this.token);
      this.graphClient = client;
      let users = service
        .GetAppSettings()
        .then((settings: any) => {
          localStorage.setItem("appSettings", JSON.stringify(settings));
          return client.api(`/$batch`).post(finalObj);
        })
        .then((res) => {
          let userResponses: any[] = [];
          res.responses.map((r: any) => {
            userResponses.push(r.body.value[0]);
          });
          return userResponses;
        });

      return users;
    });
  };
}
