import React from "react";
import AppRouting from "../../../routing/AppRouting";
import Header from "../header/header";

const AppLayout: React.FC = () => {
  return (
    <div>
      <Header />
      <div className="AppContent">
      <AppRouting />
      </div>
      
    </div>
  );
};

export default AppLayout;
