import React from "react";
import { FontIcon, getTheme } from "@fluentui/react";

import styles from "./PaginationItem.module.scss";

const PaginationItem: React.FC<{
  onClick: (event: any) => void;
  text?: string;
  icon?: string;
  isActive?: boolean;
}> = ({ onClick, text, icon, isActive }) => {
  const theme = getTheme();

  return (
    <div
      onClick={onClick}
      className={`${styles.item} ${isActive && styles.itemActive}`}
      style={{ color: theme.palette.themeDarker }}
    >
      {icon ? <FontIcon iconName={icon}></FontIcon> : <span>{text}</span>}
    </div>
  );
};

export default PaginationItem;
