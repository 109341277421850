import React, { useEffect, useMemo, useState } from "react";
import styles from "./header.module.scss";
import logo from "../../../../logo.png";
import GraphService from "../../../../common/authProvider/GraphService";
import { IAzureUserInformation } from "../../../../common/models/IUserDetails";
import { useNavigate } from "react-router-dom";
import {
  ContextualMenuItemType,
  IContextualMenuProps,
  IconButton,
  registerIcons,
} from "@fluentui/react";
import {
  SettingsIcon,
  AcceptIcon,
  PlaybackRate1xIcon,
  CompletedIcon,
  DownloadIcon,
  DoubleChevronLeftIcon,
  CopyIcon,
  MailIcon,
  SendIcon,
  CheckboxIndeterminateIcon,
  CircleRingIcon,
  ArchiveIcon,
  ResetIcon,
  PlayIcon,
  Hide3Icon,
} from "@fluentui/react-icons-mdl2";
import PermissionService from "../../../../common/api-services/PermissionService";
const Header: React.FC = () => {
  const [currentUser, setCurrentUser] =
    useState<IAzureUserInformation | null>();
  const service = useMemo(() => new PermissionService(), []);
  let navigate = useNavigate();
  registerIcons({
    icons: {
      SettingsIcon: <SettingsIcon></SettingsIcon>,
      AcceptIcon: <AcceptIcon />,
      ArchiveIcon: <ArchiveIcon />,
      PlaybackRate1xIcon: <PlaybackRate1xIcon />,
      CompletedIcon: <CompletedIcon />,
      DownloadIcon: <DownloadIcon />,
      DoubleChevronLeftIcon: <DoubleChevronLeftIcon />,
      CheckboxIndeterminateIcon: <CheckboxIndeterminateIcon />,
      CopyIcon: <CopyIcon />,
      MailIcon: <MailIcon />,
      SendIcon: <SendIcon />,
      CircleRingIcon: <CircleRingIcon />,
      ResetIcon: <ResetIcon />,
      PlayIcon: <PlayIcon />,
      Hide3Icon: <Hide3Icon />,
    },
  });

  useEffect(() => {
    let graphService = new GraphService();
    let graphUserPromise = graphService.getAzureCurrentUser();
    graphUserPromise.then((userData) => {
      service.GetByUserEmail(userData.email).then((result: any) => {
        if (result?.error) {
          userData.isAdmin = false;
          userData.isLegal = false;
          userData.isProjectManager = false;
          setCurrentUser(userData);
        } else {
          if (result != null) {
            userData.isAdmin = result.isAdmin;
            userData.isLegal = result.isLegal;
            userData.isProjectManager = result.isProjectManager;
          } else {
            userData.isAdmin = false;
            userData.isLegal = false;
            userData.isProjectManager = false;
          }
        }
        setCurrentUser(userData);
        localStorage.setItem("currentUser", JSON.stringify(userData));
      });
    });
  }, []);

  return (
    <div className={styles.header}>
      <header className={styles.siteHeader}>
        <img
          src={logo}
          className={styles.logo}
          alt="logo"
          onClick={() => navigate("/home")}
        />
        <div className={styles.title}>
          <div
            style={{ width: 500, cursor: "pointer", margin: "auto" }}
            onClick={() => navigate("/home")}
          >
            Electronic Release Letter Platform
          </div>
        </div>
        {(currentUser?.isAdmin || currentUser?.isLegal) && (
          <div className={styles.divSettingsIcon}>
            <IconButton
              iconProps={{ iconName: "SettingsIcon" }}
              menuProps={{
                shouldFocusOnMount: true,
                items: [
                  {
                    key: "User",
                    text: "User management",
                    onClick: () => navigate("/usermanagement"),
                    hidden:
                      currentUser?.isAdmin == false ||
                      currentUser == undefined ||
                      currentUser?.isAdmin == undefined,
                    disabled:
                      currentUser?.isAdmin == false ||
                      currentUser == undefined ||
                      currentUser?.isAdmin == undefined,
                  },
                  {
                    key: "divider_1",
                    itemType: ContextualMenuItemType.Divider,
                  },
                  {
                    key: "Company",
                    text: "Company management",
                    onClick: () => navigate("/companymanagement"),
                    hidden:
                      currentUser?.isLegal == false ||
                      currentUser == undefined ||
                      currentUser?.isLegal == undefined,
                    disabled:
                      currentUser?.isLegal == false ||
                      currentUser == undefined ||
                      currentUser?.isLegal == undefined,
                  },
                ],
              }}
              className={styles.settingsIcon}
            />
          </div>
        )}
        <div className={styles.signIn}>
          <span className={styles.spanSignIn}>
            Signed in - {currentUser?.displayName}
          </span>
        </div>
      </header>
    </div>
  );
};

export default Header;
